<template>
    <!-- eslint-disable-next-line vue/no-v-html -->
    <div class="max-w-prose" v-html="cardContent"></div>
</template>

<script setup lang="ts">
    import type { RichtextStoryblok } from '~/types/component-types-sb';
    import { richTextResolver } from '@storyblok/richtext';

    const props = defineProps<{
        content: RichtextStoryblok;
    }>();

    const cardContent = computed(() =>
        props.content
            ? richTextResolver({
                  resolvers: getCustomizedResolvers(),
              }).render(props.content)
            : '',
    );
</script>
